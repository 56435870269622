import { render, staticRenderFns } from "./searchStu.vue?vue&type=template&id=c3991cb8&scoped=true&"
import script from "./searchStu.js?vue&type=script&lang=js&"
export * from "./searchStu.js?vue&type=script&lang=js&"
import style0 from "./searchStu.vue?vue&type=style&index=0&id=c3991cb8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3991cb8",
  null
  
)

export default component.exports