export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      loading: false,
      companyId: '',
      // 学校ID
      schollId: '',
      nowSelectClassName: '',
      //  学校列表
      schoolList: [],
      inputClass: '',
      productId: '',
      studentId: '',
      checkResult: [],
      // 年级列表
      gradeList: [
        { value: '', label: '全部年级' },
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },

      ],
      dialogImageUrl: '',

      imgdialogVisible: false,
      // 学校选择框的数据
      selectValue: '0',
      // 年级选择框数据
      selectGrade: '全部年级',
      // 查询关键字
      classes: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 当前的页数
      rpage: 1,
      // 每页显示多少条
      rpageSize: 10,
      payResult: [],
      resultDialogVisible: false,
      fileUrl: '',
      productId: '',
      classesList: [],
      nowChoseClasses: '',
      stuName: null
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getOrganizationList()
  },
  methods: {
    // 获取机构列表
    async getOrganizationList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.selectValue = res.companyList[0].companyName
      this.schoolId = res.companyList[0].id
      this.getClassList(res.companyList[0].id)
    },
    // 选择机构查询课程
    selectGetClassList () {
      this.page = 1
      this.schoolId = this.selectValue
      this.getClassList(this.selectValue)
    },
    // 根据ID查课程列表
    async getClassList (id) {
      this.loading = true
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: id,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
      this.loading = false
    },


    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.schoolId)
    },
    // 监听pagesize改变的事件
    handlerSizeChange (newSize) {
      this.rpageSize = newSize
      this.showResultDialog(this.productId)
    },
    //  监听页码的改变
    handlerCurrentChange (newPage) {
      this.rpage = newPage
      this.showResultDialog(this.productId)
    },
    // 展示课程报名结果的对话框
    async showResultDialog (id) {
      this.productId = id
      const { data: res } = await this.$http.get('/admin/school/teacherAttendance', {
        params: {
          productId: id,
          pageNum: this.rpage,
          pageSize: this.rpageSize,

        }
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.checkResult = res.attendances
      this.rtotal = res.total
      this.resultDialogVisible = true
    },

    // 预览图片
    handlePreview (url) {
      this.dialogImageUrl = url

      this.imgdialogVisible = true
    },
  },



}
