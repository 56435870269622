export default {
  data () {
    return {
      addForm: {
      },
      addFromRules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
        rnewPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
        ],
      }
    }
  },

  created () {
    this.addForm.id = window.sessionStorage.getItem('companyId')
  },
  methods: {
    reset () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        if (this.addForm.newPassword !== this.addForm.rnewPassword) {
          this.$message.error('两次密码不一致')
          return
        }
        //  通过校验，可以发起请求
        const { data: res } = await this.$http.post('/admin/company/resetPassword', {
          id: this.addForm.id,
          password: this.addForm.newPassword
        })
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('修改密码成功')
        this.$refs.addFormRef.resetFields()
        // 清理缓存
        window.sessionStorage.removeItem('timestamp')
        // 返回登录页
        this.$router.push('/login')
      })
    },
  }
}
