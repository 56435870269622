const echarts = require("echarts")
export default {
  name: "Bank",
  data () {
    return {
      editPasswordDialogVisible: false,
      passwordForm: {}
    }
  },
  components: {},
  computed: {},
  created () {
    // 设置定时器
    // this.getIndexData()
  },
  mounted () {
    this.getIndexData()
  },
  methods: {
    getIndexData () {
      var secChart = echarts.init(document.getElementById("sec"))
      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      secChart.setOption(option)

      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    // 修改密码
    editPassword: async function () {
      if (this.passwordForm.old == window.sessionStorage.getItem('password')) {
        if (this.passwordForm.new.length >= 6 && this.passwordForm.secNew.length >= 6 && this.passwordForm.secNew == this.passwordForm.new) {
          const { data: res } = await this.$http.post('/admin/company/resetPassword', {
            id: window.sessionStorage.getItem('organizationId'),
            password: this.passwordForm.new
          })
          if (res.code !== 200) {
            this.$message.error('error')
            return
          }
          this.$message.success('修改成功')
          this.editPasswordDialogVisible = false
          window.sessionStorage.clear()
          this.$router.push("/login")
        }
      } else {
        this.$message.error("原密码错误")
      }
    },

  },

}