import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
// 导入加载动画组件
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.config.productionTip = false
Vue.prototype.$http = axios.create({
  // 公共接口
  baseURL: 'https://www.yuntaoedu.cn:50808',
  // 超时时间
  timeout: 10 * 1000,

})
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.baseURL = '/exam'
// axios.defaults.baseURL = 'https://1.117.232.42:50808'
router.beforeEach((to, from, next) => {
  // 加载进度条动画
  NProgress.start()
  next()
})

//路由进入完毕：关闭进度条
router.afterEach(() => {
  // 关闭进度条动画
  NProgress.done()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
