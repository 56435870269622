export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',

      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      lessonDetail: {},
      lessonDetailVisible: false,
      // 修改Form数据
      editForm: {},

      //     修改表单的验证规则
      editFormRules: {
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' },
          { min: 2, max: 10, message: '品牌名长度在2-10长度之间', trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入简介', trigger: 'blur' },
          { min: 5, max: 15, message: '简介在5-15长度之间', trigger: 'blur' }
        ],

      }
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('organizationId')
    this.getClassList(window.sessionStorage.getItem('organizationId'))
  },
  methods: {
    // 根据companyID查课程列表
    async getClassList (id) {
      const { data: res } = await this.$http.get('/admin/company/curriculum', {
        params: {
          companyId: id,
          pageNum: this.page,
          pageSize: this.pageSize,

        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },

    //  监听添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.addForm.picUrl = ''
      this.imageUrl = ''
    },

    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.companyId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.companyId)
    },
    // 根据课程Id获取课程详情
    async getDetail (id) {
      const { data: res } = await this.$http.get('/admin/curriculum/getCurriculumInfo', {
        params: {
          curriculumId: id
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.lessonDetail = res.curriculum
      this.lessonDetailVisible = true
    },
    // 根据id删除课程
    async removeUserById (id) {
      //  弹框询问用户是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该课程, 请确认操作?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('admin/company/curriculum', {
        params: {
          companyId: this.companyId,
          curriculumId: id
        }
      })
      if (res.code !== 200) {
        return this.$message.error('删除课程失败')
      }
      this.$message.success('删除课程成功')
      await this.getClassList(this.companyId)
    }
  }
}
