export default {
  data () {
    return {
      addForm: {
        role: 5,
        companyName: '',
      },
      schoolId: '',
      classList: [],
      selectClass: '',
      addFromRules: {
        companyName: [
          { required: true, message: '请输入机构名', trigger: 'blur' },
          { min: 2, max: 15, message: '机构名长度在2-15长度之间', trigger: 'blur' }
        ],
        id: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 5, max: 15, message: '账号长度在5-15长度之间', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '密码长度在5-15长度之间', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    // 获取缓存中的机构名字
    this.addForm.companyName = window.sessionStorage.getItem('schoolName')
    this.schoolId = window.sessionStorage.getItem('schoolId')
    this.getClassList()
  },
  methods: {
    // 根据schoolId查课程列表
    async getClassList () {
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: this.schoolId,
          pageNum: 1,
          pageSize: 100
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },

    // 新增老师账号
    addTeacher () {
      if (this.selectClass === '') {
        this.$message.error('请选择课程')
        return
      }
      this.addForm.productId = this.selectClass
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        //  通过校验，可以发起请求
        const { data: res } = await this.$http.post('/admin/company', this.addForm)
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('添加老师账号成功')
        // 添加完成隐藏对话框
      })
    },

  }
}
