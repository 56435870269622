export default {
  data () {
    return {
      addForm: {
        role: 6,
        companyName: '',
        companyId: '',
      },
      selectValue: '',
      selectClass: '',
      schoolList: [],
      classList: [],
      page: 1,
      pageSize: 10,
      nowChoseLesson: '',
      addFromRules: {
        teacherName: [
          { required: true, message: '请输入老师名', trigger: 'blur' },
          { min: 2, max: 15, message: '老师名长度在2-15长度之间', trigger: 'blur' }
        ],
        id: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 5, max: 15, message: '账号长度在5-15长度之间', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '密码长度在5-15长度之间', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    // 获取缓存中的机构名字
    this.addForm.companyName = window.sessionStorage.getItem('organizationName')
    this.addForm.companyId = window.sessionStorage.getItem('organizationId')
    this.companyId = window.sessionStorage.getItem('organizationId')
    this.getSchoolList()
  },
  methods: {

    // 获取学校列表
    async getSchoolList () {
      this.loading = true
      const { data: res } = await this.$http.get('/admin/company/qryCompanyByPage', {
        params: {
          role: 3,
          pageNum: this.page,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.total = res.total
    },
    // 根据schoolName查课程列表
    async getClassList () {
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: this.selectValue,
          pageNum: 1,
          pageSize: 100
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },
    // 新增老师账号
    addTeacher () {
      if (this.selectClass === '') {
        this.$message.error('请选择课程')
        return
      }
      this.addForm.productId = this.selectClass
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        //  通过校验，可以发起请求
        const { data: res } = await this.$http.post('/admin/company', this.addForm)
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('添加老师账号成功')
        // 添加完成隐藏对话框
      })
    },

  }
}
