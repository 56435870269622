export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',
      // 机构ID
      organizationId: '',
      //  机构列表
      organizationList: [],
      nowSelectOrganization: '',
      // 选择框的数据
      selectValue: '0',
      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      radio: '1',
      lessonDetail: {},
      lessonDetailVisible: false,
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,

      // 修改Form数据
      editForm: {},

      //     修改表单的验证规则
      editFormRules: {
        name: [
          { required: true, message: '请输入品牌名', trigger: 'blur' },
          { min: 2, max: 10, message: '品牌名长度在2-10长度之间', trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入简介', trigger: 'blur' },
          { min: 5, max: 15, message: '简介在5-15长度之间', trigger: 'blur' }
        ],

      }
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getOrganizationList()
  },
  methods: {
    // 获取机构列表
    async getOrganizationList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 2,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.organizationList = res.companyList
      this.selectValue = res.companyList[0].companyName
      this.nowSelectOrganization = res.companyList[0].companyName
      this.organizationId = res.companyList[0].id
      this.getClassList(res.companyList[0].id)
    },
    // 本地搜索结果
    querySearch (queryString, cb) {
      var orgList = this.organizationList
      var results = queryString ? orgList.filter(this.createFilter(queryString)) : orgList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (orgList) => {
        return (orgList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (item) {
      this.nowSelectOrganization = item.companyName
      this.selectValue = item.id
      this.organizationId = item.id
      this.getClassList(item.id)
    },
    // 选择机构查询课程
    selectGetClassList () {
      this.organizationId = this.selectValue
      this.nowSelectOrganization = ''
      this.getClassList(this.selectValue)
    },
    // 根据ID查课程列表
    async getClassList (id) {
      const { data: res } = await this.$http.get('/admin/company/curriculum', {
        params: {
          companyId: id,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },


    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.organizationId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.pageSize = 1
      this.getClassList(this.organizationId)
    },
    // 展示审核课程的对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('/admin/curriculum/getCurriculumInfo', {
        params: {
          curriculumId: id,
        }
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.editForm = res.curriculum
      this.editDialogVisible = true
    },
    //  监听修改对话框关闭
    editDialogClose: function () {
      this.$refs.editFormRef.resetFields()
    },
    //  审核课程
    editClassInfo () {
      this.$refs.editFormRef.validate(
        async valid => {
          if (!valid) return
          //  发起修改请求
          const { data: res } = await this.$http.put('admin/company/curriculum', {
            id: this.editForm.id,
            companyId: this.organizationId,
            curriculumSts: parseInt(this.radio),
            bookFee: this.editForm.bookFee * 100,
            classFee: this.editForm.classFee * 100,
            totalFee: this.editForm.totalFee * 100,
            openSignDate: this.editForm.openSignDate,
            closeSignDate: this.editForm.closeSignDate,
          })
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.editDialogVisible = false
          this.$refs.editFormRef.resetFields()
          await this.getClassList(this.organizationId)
        }
      )
    },
    // 根据课程Id获取课程详情
    async getDetail (id) {
      const { data: res } = await this.$http.get('/admin/curriculum/getCurriculumInfo', {
        params: {
          curriculumId: id
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.lessonDetail = res.curriculum
      this.lessonDetailVisible = true
    },
    // 计算总费用
    addNumber () {
      this.editForm.totalFee = ((this.editForm.bookFee * 1) + (this.editForm.classFee * 1)).toFixed(2)
    },
    // 根据id删除用户
    async removeUserById (id) {
      //  弹框询问用户是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 请确认操作?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('admin/company/curriculum', {
        params: {
          curriculumId: id,
          companyId: this.organizationId
        }

      })
      if (res.code !== 200) {
        return this.$message.error('删除课程失败')
      }
      this.$message.success('删除课程成功')
      await this.getClassList(this.organizationId)
    }
  }
}
