export default {
  data () {
    return {
      fullscreenLoading: true,
      // uuid
      uuid: '',
      companyId: '',
      uploadUrl: 'https://www.yuntaoedu.cn:50808/admin/company/addImage',
      // 年级列表
      gradeList: [],
      // 选择时间
      startTime: '',
      endTime: '',
      options: [{
        value: '1',
        label: '周一'
      }, {
        value: '2',
        label: '周二'
      }, {
        value: '3',
        label: '周三'
      }, {
        value: '4',
        label: '周四'
      }, {
        value: '5',
        label: '周五'
      }],
      value: '',
      classDecs: '',
      outline: [],
      addOutlineVisible: false,

      addClassTimeVisible: false,
      curriculumWeekList: [
      ],
      fileList: [],
      addDialogVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      addForm: {
        detailPics: [],
        show: []
      },
      addFromRules: {
        curriculumName: [
          { required: true, message: '请输入课程名', trigger: 'blur' },
          { min: 2, max: 20, message: '课程名长度在2-20长度之间', trigger: 'blur' }
        ],
        teacher: [
          { required: true, message: '请输入任课老师', trigger: 'blur' },
          { min: 2, max: 7, message: '老师名长度在2-7长度之间', trigger: 'blur' }
        ],
        detail: [
          { required: true, message: '请输入课程简介', trigger: 'blur' }
        ],
        teacherDetail: [
          { required: true, message: '请输入老师简介', trigger: 'blur' }
        ],

      }
    }
  },

  created () {
    this.companyId = window.sessionStorage.getItem('organizationId')
    this.createUuid()
  },
  methods: {


    // 添加课程大纲
    addOutline () {
      this.outline.push(this.classDecs)
      this.addOutlineVisible = false
    },
    // 根据下标删除大纲
    deleteOutline (index) {
      this.outline.splice(index, 1)
    },
    // 上传之前检查文件
    handleBeforeUpload (file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      const isLt2M = file.size / 1024 / 1024 <= 0.5
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 500kb!')
      }
      return isImage && isLt2M
    },
    // 创建UUid
    createUuid () {
      var s = []
      var hexDigits = "0123456789abcdef"
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = "4" // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-"

      var uuid = s.join("")
      this.uuid = uuid.replaceAll("-", "")
      if (this.uuid !== '') {
        this.fullscreenLoading = false
      }
    },

    //  监听添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 处理删除组图
    handleRemove (file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        list[i] = fileList[i].response.data
      }
    },
    // 展示图片上传成功
    // handlePicSuccess (response, file) {
    //   if (response.code !== 200) {
    //     this.$message.error(response.msg)
    //     return
    //   }
    //   this.$message.success('上传成功')
    //   this.imageUrl = URL.createObjectURL(file.raw)
    //   this.$set(this.addForm, 'picUrl', response.data)
    // },
    // 轮播图上传成功赋值给数组
    handleGallerySuccess (response, file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response.code !== 200) {
          this.$message.error(fileList[i].response.msg)
          return
        } else {
          this.$message.success('上传成功')
          list[i] = fileList[i].response.data
        }
      }
      this.addForm.gallery = list
    },
    // 宣传图上传成功赋值给数组
    handleShowSuccess (response, file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response.code !== 200) {
          this.$message.error(fileList[i].response.msg)
          return
        } else {
          this.$message.success('上传成功')
          list[i] = fileList[i].response.data
        }
      }
      this.addForm.show = list
    },
    // 详情图上传成功赋值给数组
    handleDetailSuccess (response, file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response.code !== 200) {
          this.$message.error(fileList[i].response.msg)
          return
        } else {
          this.$message.success('上传成功')
          list[i] = fileList[i].response.data
        }
      }
      this.addForm.detailpics = list
    },
    // 预览图片
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //  处理上传出错
    handleError (res) {
      this.$message.error(res.msg)
    },
    // 新增课程
    addClass () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        if (this.outline.length == 0) {
          this.$message.error('请添加课程大纲')
          return
        }
        if (this.addForm.show.length == 0 && this.addForm.detailpics.length == 0) {
          this.$message.error('请上传图片')
          return
        }
        //  通过校验，可以发起请求
        const { data: res } = await this.$http.post('/admin/company/curriculum', {
          id: this.uuid,
          curriculumName: this.addForm.curriculumName,
          companyId: this.companyId,
          curriculumWeekList: [],
          teacher: this.addForm.teacher,
          detail: this.addForm.detail,
          teacherDetail: this.addForm.teacherDetail,
          outline: this.outline,
          curriculumSts: 1,
          gradeList: []
        })
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('添加课程成功')
        // 添加完成隐藏对话框
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
        this.addForm = {}
        this.curriculumWeekList = []
        this.outline = []
        this.gradeList = []
        this.$refs.uploadOne.clearFiles()
        this.$refs.uploadTwo.clearFiles()
        this.$refs.uploadThree.clearFiles()
        this.uuid = ''
        this.createUuid()
      })
    }
  }
}
