export default {
  data () {
    return {
      loading: false,
      companyId: '',
      // 学校名
      schoolName: '',
      //  机构列表
      schoolList: [],
      // 状态列表
      statusList: [
        { key: 'U', label: '初始' },
        { key: 'S', label: '成功' },
        { key: 'F', label: '失败' },
        { key: 'B', label: '退款' },
        { key: '', label: '全部' },
      ],
      // 学校选择框的数据
      selectValue: '0',
      // 状态选择框的数据
      statusValue: '全部',
      // 学生学号
      studentId: '',
      // 学生姓名
      studentName: '',
      // 班级
      classNum: '',
      // 年级
      gradeNum: '',
      //搜姓名的学校
      searchSchool: '',
      // 课程列表
      orderList: [
      ],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      className: '',
      // 搜索学生名弹框
      searchNameVisible: false,
      // 搜索学生名弹框的数据
      searchNameList: [],
      // 年级列表
      gradeList: [
        { value: '', label: '全部年级' },
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },
        { value: 7, label: '七年级' },
        { value: 8, label: '八年级' },
        { value: 9, label: '九年级' },

      ],
      // 搜索订单号
      searchOrderId: '',
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getSchoolList()
  },
  methods: {
    // 获取学校列表
    async getSchoolList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.schoolName = res.companyList[0].companyName
      this.selectValue = res.companyList[0].companyName
      this.schoolId = res.companyList[0].id
      this.getOrderList(res.companyList[0].companyName, '')
    },
    // 选择学校查询课程
    selectSchoolGetOrderList () {
      this.page = 1
      this.schoolName = this.selectValue
      if (this.studentId !== '' || this.className !== '') {
        this.getOrderByStudentId()
      } else {
        this.getOrderList(this.selectValue, this.statusValue)
      }
    },
    // 选择状态查询课程
    selectStatusGetOrderList () {
      this.page = 1
      this.statusValue = this.statusValue
      if (this.studentId !== '' || this.className !== '') {
        this.getOrderByStudentId()
      } else {
        this.getOrderList(this.schoolName, this.statusValue)
      }

    },
    // 根据学校名查订单列表
    async getOrderList (name, status) {
      this.studentId = ''
      this.loading = true
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: this.page,
        schoolName: name,
        sts: status == '全部' ? '' : status,
      })
      // 如果订单状态为B，查询退款时间
      for (var i = 0; i < res.orderList.length; i++) {
        if (res.orderList[i].sts == 'B') {
          const { data: res1 } = await this.$http.post('order/getRefundInfo', {
            pageNum: 1,
            orderId: res.orderList[i].orderId,

          })
          if (res1.code !== 200) return this.$message.error(res1.msg)
          res.orderList[i].refundTime = res1.refundInfo[0].refund_time

        }
      }

      if (res.code !== 200) return this.$message.error(res.msg)
      this.orderList = res.orderList
      this.total = res.total
      this.loading = false
    },
    // 根据学生id查询订单列表
    async getOrderByStudentId () {
      this.loading = true
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: this.page,
        orderId: this.searchOrderId,
        stuName: this.studentName,
        stuId: this.studentId,
        schoolName: this.schoolName,
        curriculumName: this.className,
        sts: this.statusValue == '全部' ? '' : this.statusValue,
      })
      this.loading = false
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.orderList.length === 0) return this.$message.error('没有订单信息，请检查学校')
      // 如果订单状态为B，查询退款时间
      for (var i = 0; i < res.orderList.length; i++) {
        console.log(res.orderList[i].sts)
        if (res.orderList[i].sts === "B") {
          const { data: res1 } = await this.$http.post('order/getRefundInfo', {
            pageNum: 1,
            orderId: res.orderList[i].orderId,
          })
          if (res1.code !== 200) return this.$message.error(res1.msg)
          if (res1.refundInfo.length > 0) {
            for (let k = 0; k < res1.refundInfo.length; k++) {
              if (res1.refundInfo[k].refund_sts == "S") {
                console.log(res1.refundInfo[k].refund_time)
                res.orderList[i].refundTime = res1.refundInfo[k].refund_time
              } else {
                res.orderList[i].refundTime = res1.refundInfo[0].refund_time
              }
            }
          }
        }
      }
      this.orderList = res.orderList
      this.total = res.total
    },
    // 根据学生姓名查询学生id
    async showNumModal () {
      if (this.studentName == '') {
        this.$message.error('请输入学生姓名')
        return
      }
      const { data: res } = await this.$http.get('admin/getStuInfoByName', {
        params: {
          stuName: this.studentName,
          classes: this.classNum,
          grade: this.gradeNum,
          schoolName: this.searchSchool,
        }
      }
      )
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.stuInfo.length === 0) {
        this.searchNameList = []
        return this.$message.error('没有学生信息，请检查学校')
      }
      this.searchNameList = res.stuInfo
      this.searchNameVisible = true
    },
    //  监听添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.addForm.picUrl = ''
      this.imageUrl = ''
    },

    // 点击复制学号到剪切板
    copy (num) {
      var input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', num);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success('复制成功');
      }
      document.body.removeChild(input);
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      if (this.studentId !== '' || this.className !== '') {
        this.getOrderByStudentId()
      } else {
        this.getOrderList(this.schoolName, this.statusValue)
      }
    },

  }
}
