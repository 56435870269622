const echarts = require("echarts")
export default {
  name: "Bank",
  data () {
    return {
      nowSelectSchool: '',
      nowSelectSts: '成交',
      nowSelectStsValue: 'S',
      schoolList: [],
      stsList: [{ name: '未支付', value: 'U' }, {
        name: '成交',
        value: 'S',
      }, {
        name: '已退款',
        value: 'B'
      }, {
        name: '超时',
        value: 'F'
      }],
      one: {
        toolbox: {
          show: true,
          feature: {
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          // 柱状图的x轴
          type: 'category',
          axisLabel: {
            interval: 0, //控制X轴刻度全部显示
            rotate: 45 //倾斜角度
          },
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' + params[0].value + '万元'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: '20',
          }
        ]
      },
      two: {
        title: {
          text: '学校支付订单图'
        },
        toolbox: {
          show: true,
          feature: {
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          // 柱状图的x轴
          type: 'category',
          axisLabel: {
            interval: 0, //控制X轴刻度全部显示
            rotate: 30 //倾斜角度
          },
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' + params[0].value + '笔'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: '20',
          }
        ]
      },
      echartFee: {
        title: {
          text: '订单金额统计图(元)'
        },
        toolbox: {
          show: true,
          feature: {
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          // 柱状图的x轴
          type: 'category',
          axisLabel: {
            interval: 0, //控制X轴刻度全部显示
            rotate: 45 //倾斜角度
          },
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' + params[0].value + '元'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: '20',
          }
        ]
      },
      three: {
        title: {
          text: '学校支付金额图（万元）'
        },
        toolbox: {
          show: true,
          feature: {
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          // 柱状图的x轴
          type: 'category',
          axisLabel: {
            interval: 0, //控制X轴刻度全部显示
            rotate: 30 //倾斜角度
          },
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' + params[0].value + '万元'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: '20',
          }
        ]
      },
      echartNum: {
        title: {
          text: '订单数量统计图(笔)'
        },
        toolbox: {
          show: true,
          feature: {
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0, //控制X轴刻度全部显示
            rotate: 45 //倾斜角度
          },
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            return params[0].name + '<br/>' + params[0].value + '笔'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: '20',
          }
        ]
      }
    }
  },
  components: {},
  computed: {},
  created () {
    // 设置定时器
    // this.getIndexData()
  },
  mounted () {
    this.getIndexData()
    this.getSchoolMoney()
    this.getSchoolPeople()
    this.getSchoolList()
  },
  methods: {
    // 获取学校列表
    async getSchoolList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      res.companyList.unshift({ companyName: '全部' })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
    },
    async getIndexData () {
      var feeChart = echarts.init(document.getElementById("fee"))
      var numChart = echarts.init(document.getElementById("num"))
      const { data: res } = await this.$http.get('/order/getOrderTotalInfo', {
        params: {
          orderSts: 'S'
        }
      })
      // 设置echarts数据
      this.echartFee.xAxis.data = res.fee.reverse().map(item => item.lable)
      this.echartFee.series[0].data = res.fee.map(item => item.value / 100)
      this.echartNum.xAxis.data = res.num.reverse().map(item => item.lable)
      this.echartNum.series[0].data = res.num.map(item => item.value)
      // 使用刚指定的配置项和数据显示图表。
      feeChart.setOption(this.echartFee)
      numChart.setOption(this.echartNum)

    },
    // 获取学校人数
    async getSchoolPeople () {
      var people = echarts.init(document.getElementById("people"))
      var schoolTotalMoney = echarts.init(document.getElementById("schoolTotalMoney"))
      people.on(
        "click",
        (param) => {
          // 可以使用下面的方式进行路由的切换
          this.$router.push({
            path: "/manageClass",
            query: { params: param.name }
          });
        },
      );
      schoolTotalMoney.on(
        "click",
        (param) => {
          // 可以使用下面的方式进行路由的切换
          this.$router.push({
            path: "/manageClass",
            query: { params: param.name }
          });
        },
      );
      const { data: res } = await this.$http.get('/order/getSchoolNumInfo', {

      })
      // 设置echarts数据
      this.two.xAxis.data = res.result.map(item => item.schoolName)
      this.two.series[0].data = res.result.map(item => item.lable)
      this.three.xAxis.data = res.result.map(item => item.schoolName)
      this.three.series[0].data = res.result.map(item => item.value / 1000000)
      // 使用刚指定的配置项和数据显示图表。
      people.setOption(this.two)
      schoolTotalMoney.setOption(this.three)
    },
    // 获取学校金额
    async getSchoolMoney () {
      var money = echarts.init(document.getElementById("money"))
      const { data: res } = await this.$http.get('/order/getSchoolOrderInfo', {
        params: {
          sts: this.nowSelectStsValue,
          schoolName: this.nowSelectSchool == '全部' ? '' : this.nowSelectSchool
        }
      })
      if (res.result.length == 0) {
        this.$message.error('该学校暂无收入')
      } else {
        this.$message.success('已刷新')
      }
      // 设置echarts数据
      this.one.xAxis.data = res.result.reverse().map(item => item.lable)
      this.one.series[0].data = res.result.map(item => item.value / 1000000)
      // 使用刚指定的配置项和数据显示图表。
      money.setOption(this.one)
    },
    // 选择学校刷新金额
    selectSchool (e) {
      this.nowSelectSchool = e
      this.getSchoolMoney()
    },
    // 选择订单类型刷新金额
    selectSts (e) {
      this.nowSelectStsValue = e
      for (let i = 0; i < this.stsList.length; i++) {
        if (this.stsList[i].value == e) {
          this.nowSelectSts = this.stsList[i].name
        }
      }
      this.getSchoolMoney()
    }
  },

}