export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',

      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      lessonDetail: {
        baseInfo: {},
      },
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 课程详情对话框
      lessonDetailVisible: false,
      bookFee: 0,
      classFee: 0,
      totalFee: 0,
      nowChoseProductId: '',
      editMoneyVisible: false,
    }
  },
  created () {
    this.schoolId = window.sessionStorage.getItem('schoolId')
    this.schoolName = window.sessionStorage.getItem('schoolName')
    this.getClassList(window.sessionStorage.getItem('schoolId'))
  },
  methods: {
    // 根据schoolID查课程列表
    async getClassList (id) {
      const { data: res } = await this.$http.get('/admin/school/curriculum', {
        params: {
          schoolId: id,
          pageNum: this.page,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.schoolCurriculum
      this.total = res.total
    },
    // 展示修改金额弹框
    showEditMoney (id) {
      this.nowChoseProductId = id
      this.editMoneyVisible = true
      this.bookFee = 0
      this.classFee = 0
      this.totalFee = 0
    },
    // 计算总费用
    addNumber () {
      this.totalFee = ((this.bookFee - 0) + (this.classFee - 0)).toFixed(3)
    },
    // 修改金额
    async editMoney () {
      const { data: res } = await this.$http.put('/admin/school/curriculum', {
        bookFee: this.bookFee * 100,
        classFee: this.classFee * 100,
        totalFee: this.totalFee * 100,
        productId: this.nowChoseProductId
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('修改成功')
      this.editMoneyVisible = false
    },
    //  监听添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.addForm.picUrl = ''
      this.imageUrl = ''
    },

    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.schoolId)
    },
    // 根据商品id获取课程详情
    async getDetail (id, pId) {
      this.lessonDetail = {
        baseInfo: {},
      }
      const { data: res } = await this.$http.get('/mall/getCurriculumDetail', {
        params: {
          productId: pId
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res.curriculumDetail.gradeList)
      let obj = {}
      obj.gradeList = res.curriculumDetail.gradeList
      obj.curriculumWeekList = res.curriculumDetail.weeks
      obj.classNo = res.curriculumDetail.classNo
      obj.baseInfo = res.curriculumDetail.baseInfo
      this.lessonDetail = obj
      this.lessonDetailVisible = true
    },
    // 根据id删除课程
    async removeUserById (id) {
      //  弹框询问用户是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该课程, 请确认操作?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/school/curriculum', {
        params: {
          productId: id,
        }
      })
      if (res.code !== 200) {
        return this.$message.error('删除课程失败')
      }
      this.$message.success('删除课程成功')
      await this.getClassList(window.sessionStorage.getItem('schoolId'))
    }
  }
}
