export default {
  data () {
    return {
      stuId: '',
      stuInfo: [],
      newPassword: null,
      infoVisible: false
    }
  },
  created () {

  },
  methods: {
    // 获取学生信息
    async getStuInfo () {
      if (this.stuId == "") {
        this.$message.error('请输入学号')
        return
      }
      const { data: res } = await this.$http.get('admin/getStuInfoByName', {
        params: {
          id: this.stuId
        }
      })
      if (res.stuInfo.length == 0) {
        this.$message.error('学号不正确')
        return
      }
      this.stuInfo = res.stuInfo[0]
      this.infoVisible = true

    },
    // 修改学生信息
    async editStuInfo () {
      if (this.stuId == "") {
        this.$message.error('请输入学号')
        return
      }
      const { data: res } = await this.$http.post('admin/updateStudentInfo', {
        id: this.stuInfo.stuId,
        stuName: this.stuInfo.stuName,
        password: this.newPassword,
        schoolName: this.stuInfo.schoolName,
        grade: this.stuInfo.grade,
        classes: this.stuInfo.classes
      })
      if (res.code != 200) {
        this.$message.error('修改失败')

        return
      }
      this.$message.success('修改成功')
      this.infoVisible = false

    }
  },


}
