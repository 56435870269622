export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      // 学校ID
      schoolId: '',
      nowSelectClassName: '',
      //  学校列表
      schoolList: [],
      inputClass: '',
      productId: '',
      // 年级列表
      gradeList: [
        { value: '', label: '全部年级' },
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },

      ],
      // 学校选择框的数据
      selectValue: '0',
      // 年级选择框数据
      selectGrade: '全部年级',
      // 查询关键字
      classes: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      payResult: [],
      resultDialogVisible: false,
      fileDialogVisible: false,
      fileUrl: '',
    }
  },
  created () {
    this.schoolId = window.sessionStorage.getItem('schoolId')
    this.getClassList()
  },
  methods: {

    // 根据ID查课程列表
    async getClassList (id) {
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: this.schoolId,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },
    // 获取报名结果
    async getPayResult () {
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: 1,
        sts: 'S',
        productId: this.selectValue,
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.payResult = res.orderList
    },

    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.schoolId)
    },
    // 展示课程报名结果的对话框
    async showResultDialog (id) {
      this.productId = id
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: 1,
        sts: 'S',
        productId: id,
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.payResult = res.orderList
      this.resultDialogVisible = true
    },
    // 展示生成报名结果文件对话框
    async showFileDialog (id) {
      this.productId = id
      const { data: res } = await this.$http.post('/order/exportOrderInfo', {
        productId: id,
        schoolName: "",
        grade: '',
        classes: '',
        curriculumId: ''
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.fileUrl = res.url
      this.fileDialogVisible = true
    },
    // 获取文件链接
    async getFile () {
      const { data: res } = await this.$http.post('/order/exportOrderInfo', {
        productId: this.productId,
        schoolName: "",
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
        curriculumId: ''
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.fileUrl = res.url
    },
    // 选择年级获取报名结果
    async selectGetOrderList () {
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: 1,
        curriculumId: this.selectValue,
        // schoolId: this.selectValue,
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.payResult = res.orderList
    }
  }
}
