import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import OrganizationIndex from '../views/organization/organizationIndex/organizationIndex.vue'
import CompanyIndex from '../views/company/companyIndex/companyIndex.vue'
import RefundOrderList from '../views/company/refundOrderList/refundOrderList.vue'
import cWelcome from '../views/company/cWelcome/welcome.vue'
import oWelcome from '../views/organization/oWelcome/welcome.vue'
import checkClass from '../views/company/checkClass/checkClass.vue'
import addOrganization from '../views/company/addOrganization/addOrganization.vue'
import organizationList from '../views/company/organizationList/organizationList.vue'
import resetPwd from '../views/company/resetPwd/resetPwd.vue'
import addSchool from '../views/company/addSchool/addSchool.vue'
import schoolList from '../views/company/schoolList/schoolList.vue'
import orderList from '../views/company/orderList/orderList.vue'
import studentList from '../views/company/studentList/studentList.vue'
import manageClass from '../views/company/manageClass/manageClass.vue'
import payResult from '../views/company/payResult/payResult.vue'
import checkResult from '../views/company/checkResult/checkResult.vue'
import teacherResult from '../views/company/teacherResult/teacherResult.vue'
import resetStudentPwd from '../views/company/resetStudentPwd/resetStudentPwd.vue'
import searchStu from '../views/company/searchStu/searchStu.vue'
import organAddClass from '../views/organization/orgAddClass/orgAddClass.vue'
import orgAddAccount from '../views/organization/orgAddAccount/orgAddAccount.vue'
import organClassList from '../views/organization/organClassList/organClassList.vue'
import schoolIndex from '../views/school/schoolIndex/schoolIndex.vue'
import sWelcome from '../views/school/sWelcome/welcome.vue'
import schoolAddClass from '../views/school/schoolAddClass/schoolAddClass.vue'
import schoolAddAccount from '../views/school/schoolAddAccount/schoolAddAccount.vue'
import schoolClassList from '../views/school/schoolClassList/schoolClassList.vue'
import schoolChoseClass from '../views/school/choseClass/choseClass.vue'
import schoolCheckResult from '../views/school/schoolCheckResult/schoolCheckResult.vue'
import chosenClass from '../views/school/chosenClass/chosenClass.vue'

Vue.use(Router)
const router = new Router({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
      path: '/organizationIndex',
      component: OrganizationIndex,
      redirect: 'oWelcome',
      children: [
        { path: '/oWelcome', component: oWelcome },
        { path: '/organAddClass', component: organAddClass },
        { path: '/organClassList', component: organClassList },
        { path: '/orgAddAccount', component: orgAddAccount }
      ]
    },
    {
      path: '/companyIndex',
      component: CompanyIndex,
      redirect: 'cWelcome',
      children: [
        { path: '/cWelcome', component: cWelcome },
        { path: '/checkClass', component: checkClass },
        { path: '/addOrganization', component: addOrganization },
        { path: '/organizationList', component: organizationList },
        { path: '/addSchool', component: addSchool },
        { path: '/schoolList', component: schoolList },
        { path: '/orderList', component: orderList },
        { path: '/studentList', component: studentList },
        { path: '/manageClass', component: manageClass },
        { path: '/refundOrderList', component: RefundOrderList },
        { path: '/payResult', component: payResult },
        { path: '/checkResult', component: checkResult },
        { path: '/teacherResult', component: teacherResult },
        { path: '/resetStudentPwd', component: resetStudentPwd },
        { path: '/searchStu', component: searchStu },
        { path: '/resetPwd', component: resetPwd }
      ]
    },
    {
      path: '/schoolIndex',
      component: schoolIndex,
      redirect: 'sWelcome',
      children: [
        { path: '/sWelcome', component: sWelcome },
        { path: '/schoolAddClass', component: schoolAddClass },
        { path: '/schoolClassList', component: schoolClassList },
        { path: '/choseClass', component: schoolChoseClass },
        { path: '/chosenClass', component: chosenClass },
        { path: '/schoolAddAccount', component: schoolAddAccount },
        { path: '/schoolCheckResult', component: schoolCheckResult }
      ]
    }
  ]
})
// 挂载路由导航守卫，根据时间戳判断是否需要登录
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    //  对比时间戳
    if (window.sessionStorage.getItem('timestamp') == null && new Date().getTime() - window.sessionStorage.getItem('timestamp') > 1000 * 60 * 60 * 2) {
      Vue.prototype.$message({
        message: '登录超时，请重新登录',
        type: 'warning'
      })
      next('/login')
    } else {
      next()
    }
  }
})


export default router
