export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [
        {
          "name": "本校课程",
          "icon": "el-icon-date",
          "menu": [
            {
              "name": "上传课程",
              "icon": "el-icon-plus",
              "api": "schoolAddClass"
            },
            {
              "name": "课程列表",
              "icon": "el-icon-document",
              "api": "schoolClassList"
            }
          ]
        },
        {
          "name": "课程平台",
          "icon": "el-icon-date",
          "menu": [
            {
              "name": "选择课程",
              "icon": "el-icon-plus",
              "api": "choseClass"
            },
            {
              "name": "已选课程",
              "icon": "el-icon-document",
              "api": "chosenClass"
            }

          ]
        },
        {
          "name": "报名结果",
          "icon": "el-icon-user",
          "menu": [
            {
              "name": "名单查看",
              "icon": "el-icon-c-scale-to-original",
              "api": "schoolCheckResult"
            }

          ]
        },
        {
          "name": "账号管理",
          "icon": "el-icon-user",
          "menu": [
            {
              "name": "添加账号",
              "icon": "el-icon-plus",
              "api": "schoolAddAccount"
            }

          ]
        }
      ],
      isCollapse: false,
      activePath: "",
      messageNumber: 0,
      schoolName: ''
    }
  },
  created () {
    this.schoolName = window.sessionStorage.getItem('schoolName')
  },
  methods: {
    async logout () {
      window.sessionStorage.clear()
      this.$router.push("/login")
      const { data: res } = await this.$http.get('/system/logout')
      if (res.code !== 200) {
        this.$message.error('error')
        return
      }
      this.$message.success('已退出')
    },

    // 点击按钮切换菜单折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    //  保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem("activePath", activePath)
      this.activePath = activePath
    },
    // 跳转消息页面
    async messageIndex () {
      await this.$router.push("/message")
      this.activePath = ''
    }
  },
}