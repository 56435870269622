export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [
        {
          "name": "课程管理",
          "icon": "el-icon-reading",
          "menu": [
            {
              "name": "审核课程",
              "icon": "el-icon-search",
              "api": "checkClass"
            },
            {
              "name": "报名管理",
              "icon": "el-icon-date",
              "api": "manageClass"
            },
            {
              "name": "报名结果",
              "icon": "el-icon-files",
              "api": "payResult"
            },
            {
              "name": "点名查看",
              "icon": "el-icon-thumb",
              "api": "checkResult"
            },
            {
              "name": "老师签到结果",
              "icon": "el-icon-user",
              "api": "teacherResult"
            }
          ]
        },
        {
          "name": "订单管理",
          "icon": "el-icon-document-checked",
          "menu": [
            {
              "name": "订单列表",
              "icon": "el-icon-document-copy",
              "api": "orderList"
            },
            {
              "name": "退费订单",
              "icon": "el-icon-document-delete",
              "api": "refundOrderList"
            }
          ]
        },
        {
          "name": "机构管理",
          "icon": "el-icon-office-building",
          "menu": [
            {
              "name": "添加机构",
              "icon": "el-icon-circle-plus-outline",
              "api": "addOrganization"
            },
            {
              "name": "机构列表",
              "icon": "el-icon-s-fold",
              "api": "organizationList"
            }
          ]
        },
        {
          "name": "学校管理",
          "icon": "el-icon-school",
          "menu": [
            {
              "name": "添加学校",
              "icon": "el-icon-plus",
              "api": "addSchool"
            },
            {
              "name": "学校列表",
              "icon": "el-icon-s-fold",
              "api": "schoolList"
            },
            {
              "name": "上传名单",
              "icon": "el-icon-upload2",
              "api": "studentList"
            }
          ]
        },
        {
          "name": "学生管理",
          "icon": "el-icon-star-off",
          "menu": [
            {
              "name": "学生信息",
              "icon": "el-icon-search",
              "api": "searchStu"
            },
            {
              "name": "重置密码",
              "icon": "el-icon-refresh",
              "api": "resetStudentPwd"
            },
          ]
        },
        {
          "name": "系统设置",
          "icon": "el-icon-setting",
          "menu": [
            {
              "name": "重置密码",
              "icon": "el-icon-key",
              "api": "resetPwd"
            },
          ]
        }
      ],
      isCollapse: false,
      activePath: "",
      messageNumber: 0
    }
  },
  created () {
    this.activePath = window.sessionStorage.getItem("activePath")
  },
  methods: {
    async logout () {
      window.sessionStorage.clear()
      this.$router.push("/login")
      const { data: res } = await this.$http.get('/system/logout')
      if (res.code !== 200) {
        this.$message.error('连接失败')
        return
      }
      this.$message.success('您已退出')
    },

    // 点击按钮切换菜单折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    //  保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem("activePath", activePath)
      this.activePath = activePath
    },

  },
} 