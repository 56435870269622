export default {
  data () {
    return {
      loading: false,
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',
      //  机构列表
      schoolList: [],
      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,


    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getSchoolList()
  },
  methods: {
    // 获取学校列表
    async getSchoolList () {
      this.loading = true
      const { data: res } = await this.$http.get('/admin/company/qryCompanyByPage', {
        params: {
          role: 3,
          pageNum: this.page,
          pageSize: this.pageSize
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.total = res.total
      this.loading = false
    },


    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getSchoolList()
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getSchoolList()
    },
    // 重置密码
    resetPassword (id) {
      this.$confirm('确定重置该账号的密码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post('/admin/company/resetPassword', {
          id: id,
          password: '123456'
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        await this.getSchoolList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 根据id删除用户
    async removeUserById (id) {
      //  弹框询问用户是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该学校, 请确认操作?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/company', {
        params: {
          id: id,
        }
      })
      if (res.code !== 200) {
        return this.$message.error('删除学校失败')
      }
      this.$message.success('删除学校成功')
      await this.getOrganizationList()
    }
  }
}
