export default {
  data () {
    return {
      schoolId: '',
      // 机构ID
      organizationId: '',
      schoolName: '',
      //  机构列表
      organizationList: [],
      // 选择框的数据
      selectValue: '0',
      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 控制选择课程对话框的显示与隐藏
      choseClassVisible: false,
      // 当前显示的课程信息
      nowShowClassInfo: {
        maxPeople: '',
      },
      // 年级列表
      gradeList: [],
      curriculumWeekList: [],
      // 课程开始时间
      startTime: '',
      // 课程结束时间
      endTime: '',
      addClassTimeVisible: false,
      // 课程时间数组
      options: [{
        value: '1',
        label: '周一'
      }, {
        value: '2',
        label: '周二'
      }, {
        value: '3',
        label: '周三'
      }, {
        value: '4',
        label: '周四'
      }, {
        value: '5',
        label: '周五'
      }, {
        value: '6',
        label: '周六'
      }, {
        value: '7',
        label: '周日'
      }],
      value: '',
      timeArr: [],
      // 教室
      room: ''
    }
  },
  created () {
    this.schoolId = window.sessionStorage.getItem('schoolId')
    this.schoolName = window.sessionStorage.getItem('schoolName')
    this.getOrganizationList()
  },
  methods: {
    // 获取机构列表
    async getOrganizationList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 2,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.organizationList = res.companyList
      // 第一个插入本校
      this.organizationList.unshift({
        companyName: '本校',
        id: this.schoolId
      })
      this.selectValue = res.companyList[0].companyName
      this.organizationId = res.companyList[0].id
      this.getClassList(this.schoolId)
    },
    // 显示选课弹框
    showChoseDialog (data) {
      this.getClassDetail(data.id)
      this.choseClassVisible = true
    },
    // 选择机构查询课程
    selectGetClassList () {
      this.getClassList(this.selectValue)
    },
    // 根据机构ID查课程列表
    async getClassList (id) {
      const { data: res } = await this.$http.get('/admin/company/curriculum', {
        params: {
          companyId: id,
          pageNum: this.page,
          pageSize: this.pageSize,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      this.total = res.total
    },

    // 根据课程ID查课程详情
    async getClassDetail (id) {
      const { data: res } = await this.$http.get('/admin/curriculum/getCurriculumInfo', {
        params: {
          curriculumId: id,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.organizationId = res.curriculum.companyId
      this.nowShowClassInfo = res.curriculum
    },
    // 添加上课时间
    addClassTime () {
      var obj = {}
      obj.curriculumStartTime = this.startTime
      obj.curriculumEndTime = this.endTime
      obj.weeked = this.value
      if (this.startTime != '' && this.endTime != '' && this.value != '') {
        for (var i = 0; i < this.curriculumWeekList.length; i++) {
          if (this.curriculumWeekList[i].weeked == this.value) {
            this.$message.error('该时间段已存在')
            return
          }
        }
      } else {
        this.$message.error('请选择时间')
      }
      this.curriculumWeekList.push(obj)
      this.addClassTimeVisible = false
    },
    // 计算总费用
    addNumber () {
      this.nowShowClassInfo.totalFee = ((this.nowShowClassInfo.bookFee - 0) + (this.nowShowClassInfo.classFee - 0)).toFixed(3)
    },
    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.organizationId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.organizationId)
    },

    //  监听修改对话框关闭
    editDialogClose: function () {
      this.$refs.editFormRef.resetFields()
    },
    // 根据下标删除上课时间
    deleteClassTime (index) {
      this.curriculumWeekList.splice(index, 1)
    },
    // 根据id选择课程
    async choseClass () {
      if (this.nowShowClassInfo.classNo === null) return this.$message.error('请完善信息')
      if (this.nowShowClassInfo.maxPeople === null) return this.$message.error('请完善信息')
      if (this.curriculumWeekList[0].curriculumStartTime === null) return this.$message.error('请填写时间')
      if (this.curriculumWeekList[0].curriculumEndTime === null) return this.$message.error('请填写时间')
      if (this.nowShowClassInfo.totalFee === 0) return this.$message.error('总金额不可为0')
      //  弹框询问用户是否确认选择
      const confirmResult = await this.$confirm('确认选择', '选择确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消')
      }
      const { data: res } = await this.$http.post('/admin/school/curriculum', {
        schoolId: this.schoolId,
        companyId: this.organizationId,
        curriculumName: this.nowShowClassInfo.curriculumName,
        curriculumId: this.nowShowClassInfo.id,
        schoolName: this.schoolName,
        weeks: this.curriculumWeekList,
        classNo: this.nowShowClassInfo.classNo,
        maxPeople: parseInt(this.nowShowClassInfo.num),
        curriculumName: this.nowShowClassInfo.curriculumName,
        num: parseInt(this.nowShowClassInfo.num),
        minimum: parseInt(this.nowShowClassInfo.minimum),
        gradeList: this.gradeList,
        bookFee: this.nowShowClassInfo.bookFee * 100,
        classFee: this.nowShowClassInfo.classFee * 100,
        totalFee: this.nowShowClassInfo.totalFee * 100,
      })
      if (res.code !== 200) {
        return this.$message.error('选择课程失败')
      }
      this.$message.success('选择课程成功')
      this.choseClassVisible = false
      this.gradeList = []
      this.curriculumWeekList = []
      await this.getClassList(this.organizationId)
    }
  }
}
