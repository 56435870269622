export default {
  data () {
    return {
      loading: false,
      companyId: '',
      // 学校名
      schoolName: '',
      //  机构列表
      schoolList: [],
      // 状态列表
      statusList: [
        { key: 'U', label: '等待审核' },
        { key: 'S', label: '已退款' },
        { key: 'F', label: '拒绝' },
        { key: '', label: '全部' },
      ],
      // 学校选择框的数据
      selectValue: '0',
      // 状态选择框的数据
      statusValue: '',
      // 学生学号
      studentId: '',
      // 课程列表
      orderList: [
      ],
      radio: '2',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      nowSelectOrderInfo: {},
      showRefundVisible: false,
      refundFee: '',
      reason: '',
      orderId: '',
      choseRefundList: [],
      // 学生姓名
      studentName: '',
      // 班级
      classNum: '',
      // 年级
      gradeNum: '',
      //搜姓名的学校
      searchSchool: '',
      // 搜索学生名弹框
      searchNameVisible: false,
      // 搜索学生名弹框的数据
      searchNameList: [],
      // 年级列表
      gradeList: [
        { value: '', label: '全部年级' },
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },
        { value: 7, label: '七年级' },
        { value: 8, label: '八年级' },
        { value: 9, label: '九年级' },

      ],
      cRole: ''
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.cRole = window.sessionStorage.getItem('cRole')
    console.log(window.sessionStorage.getItem('cRole'))
    this.getSchoolList()
  },
  methods: {
    // 获取学校列表
    async getSchoolList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.selectValue = res.companyList[0].companyName
      this.schoolName = res.companyList[0].companyName
      this.schoolId = res.companyList[0].id
      this.getRefundOrderList(res.companyList[0].companyName, '')
    },
    // 选择学校查询课程
    selectSchoolGetOrderList () {
      this.schoolName = this.selectValue
      this.getRefundOrderList(this.selectValue, this.statusValue)
    },
    // 选择状态查询课程
    selectStatusGetOrderList () {
      this.statusValue = this.statusValue
      this.getRefundOrderList(this.schoolName, this.statusValue)
    },
    // 获取申请退费列表
    async getRefundOrderList (name, status) {
      this.loading = true
      this.studentId = ''
      const { data: res } = await this.$http.post('/order/getRefundInfo', {
        pageNum: this.page,
        refundSts: status,
        schoolName: name,
        stuId: this.studentId,
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.orderList = res.refundInfo
      this.total = res.total
      this.loading = false
    },
    // 根据id查询退费列表
    async getRefundOrderById (id) {
      const { data: res } = await this.$http.post('/order/getRefundInfo', {
        pageNum: 1,
        orderId: this.orderId
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.orderList = res.refundInfo
      this.total = res.total
    },
    // 根据学生id查询退费列表
    async getRefundOrderByStudentId () {
      const { data: res } = await this.$http.post('/order/getRefundInfo', {
        pageNum: 1,
        stuId: this.studentId,
        schoolName: this.schoolName
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.refundInfo.length === 0) return this.$message.error('没有该学生的退费信息，请检查学校')
      this.orderList = res.refundInfo
      this.total = res.total
    },
    // 展示退费弹框
    showRefundDialog (info) {
      this.showRefundVisible = true
      this.nowSelectOrderInfo = info
    },
    // 退费
    async refund () {
      const { data: res } = await this.$http.post('/order/setRefundSts', {
        refundSts: this.radio == 1 ? 'S' : 'F',
        failReason: this.reason,
        refundId: this.nowSelectOrderInfo.refund_id
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('退款成功，3秒后将刷新页面')
      this.showRefundVisible = false
      // 一秒后刷新页面
      setTimeout(() => {
        this.getRefundOrderList(this.schoolName, this.statusValue)
      }, 3000)

    },


    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      if (this.studentId !== '') {
        this.getRefundOrderByStudentId()
      } else {
        this.getRefundOrderList(this.schoolName, this.statusValue)
      }

    },
    // 多选退费订单
    handleSelectionChange (val) {
      console.log(val)
      this.choseRefundList = val;
    },
    // 批量退费
    async batchRefund () {
      let isOther = 0
      if (this.choseRefundList.length === 0) return this.$message.error('请选择要退费的订单')
      // 确认提示
      this.$confirm('此操作将批量退费，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        for (var i = 0; i < this.choseRefundList.length; i++) {
          if (this.choseRefundList[i].refund_sts === 'U') {
            const { data: res } = await this.$http.post('/order/setRefundSts', {
              refundSts: 'S',
              failReason: '',
              refundId: this.choseRefundList[i].refund_id
            })
            if (res.code !== 200) return this.$message.error(res.msg)
          } else {
            isOther = isOther + 1
          }

        }
        if (isOther == 0) {
          this.$message.success('处理成功，3秒后将刷新页面')
        } else {
          this.$message.success('处理成功，其中' + isOther + '条已退款或拒绝,' + ' ' + (this.choseRefundList.length - isOther) + '条退款成功，3秒后将刷新页面')

        }

        // 三秒后刷新页面
        setTimeout(() => {
          this.getRefundOrderList(this.schoolName, this.statusValue)
        }, 3000)
      })
    },
    // 根据学生姓名查询学生id
    async showNumModal () {

      const { data: res } = await this.$http.post('/order/getRefundInfo', {
        pageNum: 1,
        stuName: this.studentName,
        classes: this.classNum,
        grade: this.gradeNum,
        schoolName: this.schoolName
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.refundInfo.length === 0) return this.$message.error('没有该学生的退费信息，请检查学校')
      this.orderList = res.refundInfo
      this.total = res.total
    },
    // 点击复制学号到剪切板
    copy (num) {
      var input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', num);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success('复制成功');
      }
      document.body.removeChild(input);
    },
  }
}
