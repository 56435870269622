export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [
        {
          "name": "课程管理",
          "icon": "el-icon-date",
          "menu": [
            {
              "name": "上传课程",
              "icon": "el-icon-plus",
              "api": "organAddClass"
            },
            {
              "name": "课程列表",
              "icon": "el-icon-c-scale-to-original",
              "api": "organClassList"
            }
          ]
        },
        {
          "name": "账号管理",
          "icon": "el-icon-user",
          "menu": [
            {
              "name": "添加账号",
              "icon": "el-icon-plus",
              "api": "orgAddAccount"
            }

          ]
        }
      ],
      orgName: '',
      isCollapse: false,
      activePath: "",
      messageNumber: 0
    }
  },
  created () {
    this.orgName = window.sessionStorage.getItem('organizationName')
  },
  methods: {
    async logout () {
      window.sessionStorage.clear()
      this.$router.push("/login")
      const { data: res } = await this.$http.get('/system/logout')
      if (res.code !== 200) {
        this.$message.error('error')
        return
      }
      this.$message.success('退出成功')
    },

    // 点击按钮切换菜单折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    //  保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem("activePath", activePath)
      this.activePath = activePath
    },
    // 跳转消息页面
    async messageIndex () {
      await this.$router.push("/message")
      this.activePath = ''
    }
  },
}