export default {
  data () {
    return {
      addForm: {
        role: 2
      },
      addFromRules: {
        companyName: [
          { required: true, message: '请输入机构名', trigger: 'blur' },
          { min: 2, max: 15, message: '机构名长度在2-15长度之间', trigger: 'blur' }
        ],
        id: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 5, max: 15, message: '账号长度在5-15长度之间', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '密码长度在5-15长度之间', trigger: 'blur' }
        ]
      }
    }
  },

  created () {

  },
  methods: {
    // 新增机构
    addOrganization () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        //  通过校验，可以发起请求
        const { data: res } = await this.$http.post('/admin/company', this.addForm)
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('添加机构成功')
        // 添加完成隐藏对话框
      })
    },

  }
}
