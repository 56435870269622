export default {
  data () {
    return {
      loading: false,
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',
      // 学校ID
      schoolId: '',
      nowSelectClassName: '',
      //  学校列表
      schoolList: [],
      inputClass: '',
      productId: '',
      peopleFileUrl: '',
      // 年份列表
      yearList: [],
      yearValue: '',
      // 学期
      yearTypeList: [
        { value: 'S1', label: '上学期' },
        { value: 'S2', label: '下学期' },
      ],
      yearTypeValue: '',
      // 年级列表
      gradeList: [
        { value: '', label: '全部年级' },
        { value: 1, label: '一年级' },
        { value: 2, label: '二年级' },
        { value: 3, label: '三年级' },
        { value: 4, label: '四年级' },
        { value: 5, label: '五年级' },
        { value: 6, label: '六年级' },

      ],
      // 学校选择框的数据
      selectValue: '0',
      // 年级选择框数据
      selectGrade: '全部年级',
      // 查询关键字
      classes: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 结果的页数
      rpage: 1,
      rtotal: 0,
      payResult: [],
      resultDialogVisible: false,
      fileDialogVisible: false,
      peopleDialogVisible: false,
      fileUrl: '',
      lessonDetail: {
        baseInfo: {
          curriculum_name: '',
          detail: '',
          teacher: '',
          book_fee: '',
          class_fee: '',
          total_fee: '',
        },

      },
      lessonDetailVisible: false,
      schoolName: '',
      allSchoolFileUrl: '',
      allSchoolVisible: false,
      lessonName: '',
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getOrganizationList()
    var year = new Date().getFullYear()
    this.yearValue = year

    this.yearTypeValue = (new Date().getMonth() + 1) >= 8 ? 'S2' : 'S1'
    for (let i = 2022; i <= year; i++) {
      this.yearList.push({ value: i })
    }
  },
  methods: {
    // 获取机构列表
    async getOrganizationList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.selectValue = res.companyList[0].companyName
      this.schoolName = res.companyList[0].companyName
      this.schoolId = res.companyList[0].id
      this.getClassList(res.companyList[0].id)
    },
    // 选择机构查询课程
    selectGetClassList () {
      this.page = 1
      this.schoolId = this.selectValue
      this.getClassList(this.selectValue)
      this.schoolList.forEach((item) => {
        if (item.id == this.selectValue) {
          this.schoolName = item.companyName
        }
      })
    },
    // 根据ID查课程列表
    async getClassList (id) {
      this.loading = true
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: id,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.classList = res.curriculumList
      if (this.classList.length > 0) {
        for (var i = 0; i < this.classList.length; i++) {
          var pid = this.classList[i].product_id
          const { data: res } = await this.$http.post('order/qryOrder', {
            pageNum: 1,
            sts: 'S',
            productId: pid,
            grade: '',
            classes: this.classes,
            pageNum: 1,
          })
          this.classList[i].total = res.total
        }
      }
      this.classList = res.curriculumList
      this.total = res.total
      this.loading = false
    },
    // 根据课程名查询课程
    async getClassByName () {
      if (this.lessonName === '') {
        this.getClassList(this.schoolId)
        return
      }
      this.loading = true
      this.classList = []
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculumByName', {
        params: {
          schoolId: this.schoolId,
          curriculumName: this.lessonName,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.curriculumList.length > 0) {
        for (var i = 0; i < res.curriculumList.length; i++) {
          var pid = res.curriculumList[i].product_id
          const { data: reso } = await this.$http.post('order/qryOrder', {
            pageNum: 1,
            sts: 'S',
            productId: pid,
            grade: '',
            classes: '',
            pageNum: 1,
          })
          res.curriculumList[i].total = reso.total
        }
      }

      this.classList = res.curriculumList
      this.loading = false
      this.total = res.total
    },
    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleRCurrentChange (newPage) {
      this.rpage = newPage
      this.showResultDialog(this.productId)
    },
    // 展示课程报名结果的对话框
    async showResultDialog (id) {
      this.selectGrade = '全部年级'
      this.productId = id
      const { data: res } = await this.$http.post('order/qryOrder', {
        sts: 'S',
        productId: id,
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
        pageNum: this.rpage,
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.rtotal = res.total
      this.payResult = res.orderList
      this.resultDialogVisible = true
    },
    // 展示生成报名结果文件对话框
    async showFileDialog (id) {
      this.productId = id
      const { data: res } = await this.$http.post('/order/exportOrderInfo', {
        productId: id,
        schoolName: "",
        grade: '',
        classes: '',
        curriculumId: ''
      })
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.fileUrl = res.url
      this.fileDialogVisible = true
    },
    // 展示生成报名结果文件对话框
    async showPeopleDialog (id) {
      this.productId = id
      const { data: res } = await this.$http.post('/admin/school/exportClassStuInfo', {
        productId: id,
      })
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.peopleFileUrl = res.url
      this.peopleDialogVisible = true
    },
    // 获取文件链接
    async getFile () {
      const { data: res } = await this.$http.post('/order/exportOrderInfo', {
        productId: this.productId,
        schoolName: "",
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
        curriculumId: ''
      })
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.fileUrl = res.url
    },
    // 选择年级获取报名结果
    async selectGetOrderList () {
      const { data: res } = await this.$http.post('order/qryOrder', {
        pageNum: 1,
        sts: 'S',
        productId: this.productId,
        grade: this.selectGrade == '全部年级' ? '' : this.selectGrade,
        classes: this.classes,
      })
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.rtotal = res.total
      this.payResult = res.orderList
    },
    // 根据商品id获取课程详情
    async getGoodsDetail (pId) {
      const { data: res } = await this.$http.get('/mall/getCurriculumDetail', {
        params: {
          productId: pId
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.lessonDetail.baseInfo = res.curriculumDetail.baseInfo
      this.lessonDetail.curriculumWeekList = res.curriculumDetail.weeks
      this.lessonDetail.classNo = res.curriculumDetail.classNo
      this.lessonDetailVisible = true
    },
    // 获取全校名单
    async getAll () {

      // 确认是否获取
      this.$confirm('确认获取全校名单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        const { data: res } = await this.$http.post('/order/exportOrderInfoByWeeked', {
          schoolName: this.schoolName,
          semester: this.yearValue + ' ' + this.yearTypeValue
        })
        if (res.code !== 200) {
          this.loading = false
          return this.$message.error(res.msg)

        }
        this.allSchoolFileUrl = res.url
        this.loading = false
        this.allSchoolVisible = true

      })
    }
  }
}
