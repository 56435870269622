export default {
  data () {
    return {
      uploadUrl: 'https://www.yuntaoedu.cn:50808/admin/curriculum/resetImage',
      companyId: '',
      // 学校ID
      schoolId: '',
      //  学校列表
      schoolList: [],
      // 选择框的数据
      selectValue: '0',
      lessonDetail: {},
      lessonDetailVisible: false,
      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      loading: false,
      bookFee: 0,
      classFee: 0,
      totalFee: 0,
      nowChoseProductId: '',
      editMoneyVisible: false,
      editPeopleVisible: false,
      editDetail: {

      },
      newGradeList: [],
      newCurriculumWeekList: [],
      editDetailVisible: false,
      value: '',
      // 课程开始时间
      startTime: '',
      // 课程结束时间
      endTime: '',
      addClassTimeVisible: false,
      // 课程时间数组
      options: [{
        value: '1',
        label: '周一'
      }, {
        value: '2',
        label: '周二'
      }, {
        value: '3',
        label: '周三'
      }, {
        value: '4',
        label: '周四'
      }, {
        value: '5',
        label: '周五'
      }, {
        value: '6',
        label: '周六'
      }, {
        value: '7',
        label: '周日'
      }],
      newMaxPeople: 0,
      lessonName: '',
      nowCurriculumId: '',
      cRole: "",
      nowPagePeople: 0,
      nowPageMoney: 0,
      schoolTotalPeople: 0,
      schoolTotalMoney: 0,
      srcList: [],
      fileList: [],
      uuid: '',
      jumpSchoolName: this.$route.query.params
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.cRole = window.sessionStorage.getItem('cRole')
    this.getOrganizationList()
  },
  methods: {
    // 获取机构列表
    async getOrganizationList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (this.jumpSchoolName !== undefined) {
        var id = null
        for (var i = 0; i < res.companyList.length; i++) {
          if (res.companyList[i].companyName == this.jumpSchoolName) {
            id = res.companyList[i].id
          }
        }
        this.getSchoolTotalOrder(this.jumpSchoolName)
        this.schoolList = res.companyList
        this.selectValue = this.jumpSchoolName
        this.schoolId = id
        this.getClassList(id)
      } else {
        this.getSchoolTotalOrder(res.companyList[0].companyName)
        this.schoolList = res.companyList
        this.selectValue = res.companyList[0].companyName
        this.schoolId = res.companyList[0].id
        this.getClassList(res.companyList[0].id)
      }


    },
    // 查询当前学校的订单总数和支付人数
    async getSchoolTotalOrder (name) {
      const { data: res } = await this.$http.get('order/getSchoolNumInfo', {
        params: {
          schoolName: name
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolTotalPeople = res.result.length > 0 ? res.result[0].lable : 0
      this.schoolTotalMoney = res.result.length > 0 ? res.result[0].value : 0

    },
    // 选择机构查询课程
    selectGetClassList () {
      this.organizationId = this.selectValue
      this.page = 1
      this.getClassList(this.selectValue)
      for (var i = 0; i < this.schoolList.length; i++) {
        if (this.schoolList[i].id == this.selectValue) {
          this.getSchoolTotalOrder(this.schoolList[i].companyName)
        }
      }
    },
    // 根据学校ID查课程列表
    async getClassList (id) {
      this.loading = true
      this.lessonName = ''
      this.classList = []
      this.schoolId = id
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculum', {
        params: {
          schoolId: id,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.curriculumList.length > 0) {
        for (var i = 0; i < res.curriculumList.length; i++) {
          var pid = res.curriculumList[i].product_id
          const { data: reso } = await this.$http.post('order/qryOrder', {
            pageNum: 1,
            sts: 'S',
            productId: pid,
            grade: '',
            classes: '',
            pageNum: 1,
          })
          res.curriculumList[i].total = reso.total
          const { data: resoo } = await this.$http.get('order/getProductTotalInfo', {
            params: {
              productIdList: pid,
            }
          })
          res.curriculumList[i].moneyTotal = resoo.totalInfo.length > 0 ? resoo.totalInfo[0].totalFee / 100 : 0
        }
      }
      var t = 0
      var m = 0
      for (i = 0; i < res.curriculumList.length; i++) {
        t = t + res.curriculumList[i].total
        m = m + res.curriculumList[i].moneyTotal
      }
      this.nowPagePeople = t
      this.nowPageMoney = m
      this.classList = res.curriculumList
      this.loading = false
      this.total = res.total
    },
    // 根据课程名查询课程
    async getClassByName () {
      if (this.lessonName === '') {
        this.getClassList(this.schoolId)
        return
      }
      this.loading = true
      this.classList = []
      const { data: res } = await this.$http.get('/admin/school/getSchoolCurriculumByName', {
        params: {
          schoolId: this.schoolId,
          curriculumName: this.lessonName,
          pageSize: this.pageSize,
          pageNum: this.page,
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.curriculumList.length > 0) {
        for (var i = 0; i < res.curriculumList.length; i++) {
          var pid = res.curriculumList[i].product_id
          const { data: reso } = await this.$http.post('order/qryOrder', {
            pageNum: 1,
            sts: 'S',
            productId: pid,
            grade: '',
            classes: '',
            pageNum: 1,
          })

          res.curriculumList[i].total = reso.total

        }

      }

      this.classList = res.curriculumList
      this.loading = false
      this.total = res.total
    },
    // 根据商品id获取课程详情
    async getDetail (id, pId) {
      const { data: res } = await this.$http.get('/mall/getCurriculumDetail', {
        params: {
          productId: pId
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      let obj = {}
      obj = res.curriculumDetail.baseInfo
      obj.gradeList = res.curriculumDetail.gradeList
      obj.curriculumWeekList = res.curriculumDetail.weeks
      obj.classNo = res.curriculumDetail.classNo
      obj.images = res.curriculumDetail.images
      let arr = []
      for (let i = 0; i < res.curriculumDetail.images.length; i++) {
        arr.push('https://asspfile-1312574989.cos-website.ap-nanjing.myqcloud.com/' + res.curriculumDetail.images[i].name)
      }
      this.srcList = arr
      this.lessonDetail = obj
      this.createUuid()
      this.lessonDetailVisible = true
    },
    // 详情图上传成功赋值给数组
    handleDetailSuccess (response, file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response.code !== 200) {
          this.$message.error(fileList[i].response.msg)
          return
        } else {
          this.$message.success('修改已生效')
          list[i] = fileList[i].response.data
        }
      }
      // this.addForm.detailpics = list 待修改
    },
    // 上传之前检查文件
    handleBeforeUpload (file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      const isLt2M = file.size / 1024 / 1024 <= 0.5
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 500kb!')
      }
      return isImage && isLt2M
    },
    // 预览图片
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //  处理上传出错
    handleError (res) {
      this.$message.error(res.msg)
    },
    // 创建UUid
    createUuid () {
      var s = []
      var hexDigits = "0123456789abcdef"
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = "4" // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-"

      var uuid = s.join("")
      this.uuid = uuid.replaceAll("-", "")
      if (this.uuid !== '') {
        this.fullscreenLoading = false
      }
    },
    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getClassList(this.schoolId)
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getClassList(this.schoolId)
    },
    // 展示审核课程的对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('/admin/curriculum/getCurriculumInfo', {
        params: {
          curriculumId: id,
        }
      })
      if (res.code !== 200) {
        return this.$message.error('获取失败，请稍后再试')
      }
      this.editForm = res.curriculum
      this.editDialogVisible = true
    },
    //  开启关闭报名
    async changeClassStatus (status, id) {
      // 确认操作提示
      const confirm = await this.$confirm('确认操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      const { data: res } = await this.$http.post('/admin/school/SignCurriculm', {
        productId: id,
        type: parseInt(status)
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      await this.getClassList(this.schoolId)
    },
    // 批量开启报名
    async openAll () {
      // 确认操作提示
      const confirm = await this.$confirm('确认批量开启操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      const { data: res } = await this.$http.post('/admin/school/SignCurriculm', {
        schoolId: this.schoolId,
        type: 1
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      await this.getClassList(this.schoolId)
    },
    // 批量关闭报名
    async closeAll () {
      // 确认操作提示
      const confirm = await this.$confirm('确认批量关闭操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      const { data: res } = await this.$http.post('/admin/school/SignCurriculm', {
        schoolId: this.schoolId,
        type: 2
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      await this.getClassList(this.schoolId)
    },
    // 批量退款
    async batchRefund (id) {
      // 加载框提示
      const loading = this.$loading({
        lock: true,
        text: '正在查询是否可批量退款中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { data: res } = await this.$http.post('order/createBatchReFund', {
        productId: id
      })
      loading.close()
      if (res.code !== 200) return this.$message.error(res.msg)
      // 关闭加载框
      if (res.batchId) {
        const confirm = await this.$confirm('确认批量退款操作吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (!confirm) return
        const { data: newRes } = await this.$http.post('order/batchRefund', {
          batchId: res.batchId
        })
        if (newRes.code !== 200) return this.$message.error(newRes.msg)
        this.$message.success(newRes.msg)

      } else {
        this.$message.error('该课程没有可退款的订单')
      }

      await this.getClassList(this.organizationId)
    },
    // 展示修改金额弹框
    showEditMoney (id) {
      this.nowChoseProductId = id
      this.editMoneyVisible = true
      this.bookFee = 0
      this.classFee = 0
      this.totalFee = 0
    },
    // 展示修改人数弹框
    showEditPeople (id) {
      this.nowChoseProductId = id
      this.editPeopleVisible = true
      this.newMaxPeople = 0
      this.newMiniPeople = 0
    },
    // 计算总费用
    addNumber () {
      this.totalFee = ((this.bookFee - 0) + (this.classFee - 0)).toFixed(3)
    },
    // 修改金额
    async editMoney () {
      // 确认操作提示
      const confirm = await this.$confirm('确认修改金额吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      const { data: res } = await this.$http.put('/admin/school/curriculum', {
        bookFee: this.bookFee * 100,
        classFee: this.classFee * 100,
        totalFee: this.totalFee * 100,
        productId: this.nowChoseProductId
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('修改成功')
      this.editMoneyVisible = false
    },
    // 修改人数
    async editPeople () {
      if (this.newMaxPeople == 0) {
        this.$message.error('最大人数不能为0')
        return
      }
      // 确认操作提示
      const confirm = await this.$confirm('确认修改人数吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      const { data: res } = await this.$http.put('/admin/school/curriculum', {
        maxPeople: parseInt(this.newMaxPeople),
        productId: this.nowChoseProductId
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('修改成功')
      this.editPeopleVisible = false
    },
    //修改获取课程详情
    async getEditDetail (id, pId) {
      this.nowChoseProductId = pId
      this.nowCurriculumId = id
      const { data: res } = await this.$http.get('/mall/getCurriculumDetail', {
        params: {
          productId: pId
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.editDetail = res.curriculumDetail.baseInfo
      this.editDetail.gradeList = res.curriculumDetail.gradeList
      this.editDetail.curriculumWeekList = res.curriculumDetail.weeks
      for (let i = 0; i < res.curriculumDetail.weeks.length; i++) {
        res.curriculumDetail.weeks[i].curriculumId = this.nowCurriculumId + '|' + this.nowChoseProductId + '|' + this.schoolId
      }
      this.newCurriculumWeekList = res.curriculumDetail.weeks
      this.editDetail.classNo = res.curriculumDetail.classNo
      console.log(this.editDetail)
      this.editDetailVisible = true
    },
    // 添加上课时间
    addClassTime () {
      var obj = {}
      obj.curriculumId = this.nowCurriculumId + '|' + this.nowChoseProductId + '|' + this.schoolId
      obj.curriculumStartTime = this.startTime
      obj.curriculumEndTime = this.endTime
      obj.weeked = this.value
      if (this.startTime != '' && this.endTime != '' && this.value != '') {
        for (var i = 0; i < this.newCurriculumWeekList.length; i++) {
          if (this.newCurriculumWeekList[i].weeked == this.value) {
            this.$message.error('该时间段已存在')
            return
          }
        }
      } else {
        this.$message.error('请选择时间')
      }
      this.newCurriculumWeekList.push(obj)
      this.addClassTimeVisible = false
    },
    // 处理删除组图
    handleRemove (file, fileList) {
      const list = []
      for (let i = 0; i < fileList.length; i++) {
        list[i] = fileList[i].response.data
      }
    },
    // 根据下标删除上课时间
    deleteClassTime (index) {
      this.newCurriculumWeekList.splice(index, 1)
    },
    clearList () {
      this.fileList = []
      this.newCurriculumWeekList = []
      this.editDetailVisible = false
    },
    // 提交修改信息
    async editInfo () {

      // 确认操作提示
      const confirm = await this.$confirm('确认修改课程信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!confirm) return
      if (this.newGradeList.length == 0) {
        this.newGradeList = this.editDetail.gradeList
      }
      if (this.newCurriculumWeekList.length == 0) {
        this.newCurriculumWeekList = this.editDetail.curriculumWeekList
      }
      const { data: res } = await this.$http.put('/admin/company/curriculum', {
        curriculumName: this.editDetail.curriculumName,
        detail: this.editDetail.detail,
        teacher: this.editDetail.teacher,
        classNo: this.editDetail.classNo,
        companyId: this.schoolId,
        schoolId: this.schoolId,
        id: this.nowCurriculumId,
        curriculumWeekList: this.newCurriculumWeekList,
        productId: this.nowChoseProductId,
        curriculumId: this.nowCurriculumId
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('修改成功')
      this.editDetailVisible = false
    },
  }
}
