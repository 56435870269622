export default {
  data () {
    return {
      adminUrl: 'http://wx.ahjunze.com/',
      uploadUrl: '/exam/brand/upload',
      companyId: '',
      selectValue: '',
      schoolName: '',
      // 学生数信息
      studentNumList: [],
      schoolList: [],
      //  机构列表
      organizationList: [],
      // 查询关键字
      keyword: '',
      // 课程列表
      classList: [],
      addDialogVisible: false,
      addForm: {},
      radio: '1',
      total: 0,
      // 当前的页数
      page: 1,
      // 每页显示多少条
      pageSize: 10,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      fileList: [],
      deleteDialogVisible: false,
      resetDialogVisible: false,
      schoolPassword: null,
      nowChoseSchool: '',
      // 删除用户的id
      deleteId: '',
    }
  },
  created () {
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getStudentNum()
    this.getSchoolList()
  },
  methods: {
    // 获取学校学生数据
    async getStudentNum () {
      const { data: res } = await this.$http.get('/admin/school/getSchoolStuNum', {
        params: {
          pageNum: this.page,
          pageSize: this.pageSize
        }
      })

      this.total = res.total
      if (res.code !== 200) return this.$message.error(res.msg)
      this.studentNumList = res.schoolStuNum
    },

    // 获取学校列表
    async getSchoolList () {
      const { data: res } = await this.$http.get('/admin/company', {
        params: {
          role: 3
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.schoolList = res.companyList
      this.schoolName = res.companyList[0].companyName
    },

    // 打开上传学生名单窗口
    openDialog () {
      this.fileList = []
      this.schoolName = ''
      this.selectValue = ''
      this.addDialogVisible = true
    },
    // 打开删除学生名单窗口
    deleteDialog () {
      this.deleteId = ''
      this.deleteDialogVisible = true;
    },
    // 打开重置密码对话框
    showResetModal (name) {
      this.schoolPassword = ''
      this.nowChoseSchool = name
      this.resetDialogVisible = true
    },
    // 重置全校密码
    async resetSchoolPassword () {
      if (this.schoolPassword == '') {
        return this.$message.error('请输入口令')
      }
      if (this.schoolPassword != this.nowChoseSchool) {
        return this.$message.error('口令错误')
      }
      // 弹出确认框
      const confirmResult = await this.$confirm('重置' + this.nowChoseSchool + '全校密码吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 如果用户点击了取消, 直接return
      if (confirmResult !== 'confirm') return this.$message.info('已取消')
      const { data: res } = await this.$http.post('/admin/reSetPasswordBySchoolName', {
        schoolName: this.nowChoseSchool,
        password: '123456'
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.resetDialogVisible = false
      this.getStudentNum()
    },
    // 删除学生名单
    async deleteStu () {
      // 弹出确认框
      const confirmResult = await this.$confirm('此操作将永久删除该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 如果用户点击了取消, 直接return
      if (confirmResult !== 'confirm') return this.$message.info('已取消删除')
      const { data: res } = await this.$http.delete('/admin/deleteStudentByStuId', {
        params: {
          stuId: this.deleteId
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.deleteDialogVisible = false
      this.getStudentNum()
    },
    // 监听pagesize改变的事件
    handleSizeChange (newSize) {
      this.pageSize = newSize
      this.getStudentNum()
    },
    //  监听页码的改变
    handleCurrentChange (newPage) {
      this.page = newPage
      this.getStudentNum()
    },

    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 处理选择框的改变
    selectSchool () {
      this.schoolName = this.selectValue
    },
    uploadSuccess (res) {
      if (res.code !== 200) return this.$message.error(res.msg)
      this.addDialogVisible = false
      this.$message.success('添加成功')
      this.getStudentNum()
    },
    // 根据id删除学校学生信息
    async removeUserById (name) {
      //  弹框询问是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该学校学生信息, 请确认操作?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/student', {
        params: {
          schoolName: name,
        }
      })
      if (res.code !== 200) {
        return this.$message.error('删除信息失败')
      }
      this.$message.success('删除学生信息成功')
      await this.getStudentNum()
    }
  }
}
