import { render, staticRenderFns } from "./schoolCheckResult.vue?vue&type=template&id=61cd6522&scoped=true&"
import script from "./schoolCheckResult.js?vue&type=script&lang=js&"
export * from "./schoolCheckResult.js?vue&type=script&lang=js&"
import style0 from "./schoolCheckResult.vue?vue&type=style&index=0&id=61cd6522&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61cd6522",
  null
  
)

export default component.exports