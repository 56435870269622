<template>
  <div class="login_container">
    <canvas id="canv"></canvas>
    <div class="login_box">
      <div class="avatar_box">
        <img src="../assets/logo.png" alt="logo">
      </div>
      <!--登录表单区域-->
      <el-form :model="loginForm" :rules="loginFormRule" ref="loginFromRef" label-width="0px" class="login_form"
        @keyup.enter.native="login">
        <el-form-item prop="userName">
          <el-input v-model="loginForm.userName" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input v-model="loginForm.passWord" type="password" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="code" autocomplete="off" placeholder="请输入验证码" style="width:150px;float:left"></el-input>
          <div style="float:right;height:50px" @click="refreshCode">
            <!--验证码组件-->
            <Sidentify :identifyCode="identifyCode" />
          </div>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button style="color:black" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="master">
      <a href="http://beian.miit.gov.cn/" style="color:white">
        皖ICP备2022010729号
      </a>
    </div>

  </div>
</template>

<script>
import Sidentify from '../components/Sidentify.vue'
import { stars_nest } from '../utils/nest.js'
import organizationIndex from './organization/organizationIndex/organizationIndex'
export default {
  data () {
    return {
      //  用户输入的验证码
      code: '',
      // 验证码
      identifyCodes: "1234567890",
      identifyCode: '',
      // 登录表单数据
      loginForm: {
        // userName: '34010001',
        // passWord: '11111'
        userName: '',
        passWord: ''
      },
      //  表单验证规则
      loginFormRule: {
        // 验证用户名
        userName: [
          {
            require: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '长度在3到15个字符',
            trigger: 'blur'
          }
        ],
        // 验证密码
        passWord: [
          {
            require: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 5,
            max: 15,
            message: '长度在5到15个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  components: {
    Sidentify
  },
  created () {
    window.sessionStorage.setItem('activePath', '')
    // 初始化验证码
    this.identifyCode = ""
    this.makeCode(this.identifyCodes, 4)
    console.log('欢迎使用!如有BUG请联系15055700457反馈，感谢！')
  },
  mounted () {

  },
  methods: {
    // 点击重置按钮
    resetLoginForm () {
      this.$refs.loginFromRef.resetFields()
    },
    login () {
      this.$refs.loginFromRef.validate(async (valid) => {
        if (this.code !== this.identifyCode) {
          this.refreshCode()
          return this.$message.error('验证码错误')
        }
        if (this.loginForm.userName === '') return this.$message.error('请输入用户名')
        if (this.loginForm.passWord === '') return this.$message.error('请输入密码')
        if (!valid) return
        const { data: res } = await this.$http.post('/system/login', {
          username: this.loginForm.userName,
          password: this.loginForm.passWord,
        }, { Headers: { 'Content-Type': 'application/json' } })
        if (res.code !== 200) {
          this.refreshCode()
          return this.$message.error(res.msg)
        }
        // 获取当前时间戳
        const timestamp = new Date().getTime()
        // 将时间戳存入sessionStorage
        window.sessionStorage.setItem('timestamp', timestamp)
        // 将用户信息存入sessionStorage
        window.sessionStorage.setItem('password', this.loginForm.passWord)
        // window.sessionStorage.setItem('token', res.token)
        this.$message.success('登陆成功')
        if (res.role == "ROLE_COM") {
          window.sessionStorage.setItem('activePath', 'oWelcome')
          window.sessionStorage.setItem('organizationId', res.id)
          window.sessionStorage.setItem('organizationName', res.username)
          await this.$router.push('/organizationIndex')
        } else if (res.role == "ROLE_ADM") {
          window.sessionStorage.setItem('activePath', 'companyIndex')
          window.sessionStorage.setItem('companyId', res.id)
          if (res.id == 'tuifei') {
            window.sessionStorage.setItem('cRole', 'Top')
          } else {
            window.sessionStorage.setItem('cRole', '')
          }

          await this.$router.push('/companyIndex')
        } else if (res.role == "ROLE_SCH") {
          window.sessionStorage.setItem('activePath', 'schoolIndex')
          window.sessionStorage.setItem('schoolId', res.id)
          window.sessionStorage.setItem('schoolName', res.username)
          await this.$router.push('/schoolIndex')
        }
      })
    },

    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode () {
      this.identifyCode = ""
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
  }
}
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  background-image: -webkit-radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
  background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
  cursor: arrow;
}

.login_box {
  height: 300px;
  width: 450px;
  border-radius: 50px;
  background-image: -webkit-radial-gradient(ellipse farthest-corner at center top, #000d4d 30%, #000105 100%);
  background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 30%, #000105 100%);
  cursor: arrow;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;

}

.avatar_box {
  width: 130px;
  height: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  left: 10%;
  bottom: 0;
  width: 80%;
  padding: 0 10px;
  box-sizing: border-box;
  align-items: center;
}

.master {
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;

}

html {
  height: 100%;
  background-image: -webkit-radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
  background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
  cursor: arrow;
}

body {
  width: 100%;
  margin: 0;
  overflow: hidden;
}
</style>
